<template>
  <div class="DisassemblyRecord">
	  <van-popup v-model="shows" round :close-on-click-overlay="false">
		   <div class="Dialog" v-show="shows_a">
				<div class="title">系统提示</div>
				<div class="mains">请选择保存类型</div>
				<div class="btnList">
				<span @click="shows = false">取消</span>
				<span
					style="border-right: 0.9px solid; border-left: 0.9px solid"
					@click="goSave()"
					>拆解完成</span
				>
				<span @click="goDistribution('1')">仅保存</span>
				</div>
			</div>
			
	  </van-popup>
	  <van-popup v-model="shows_b" round  class="popup" :close-on-click-overlay="false">
		  	<div class="Dialog" v-show="shows_b">
          <div class="title">系统提示</div>
          <div class="mains">点击拆解后不可再次编辑</div>
          <div class="btnList">
            <span style="border-right: 1px solid" @click="finishEnd()">取消</span>
            <span @click="goDistribution('2')">确定拆解完成</span>
          </div>
			</div>
	  </van-popup>

    <div class="header">
      <span @click="previousPage()"></span>
      <span>开始拆解</span>
      <!-- <span @click="goDistribution()">完成</span> -->
      <span @click="finish()">完成</span>
    </div>

    <div class="box">
      	<!-- <van-field readonly clickable :value="date" label-width="110px" label="拆解时间：" placeholder="请选择" @click="Tame = true"  > </van-field> -->
            <!-- <van-popup v-model="Tame" position="bottom">
				<van-datetime-picker
					v-model="currentDate"
					type="datetime"
					title="选择年月日"
					@confirm="confirmHan"
          
					/>
        	</van-popup> -->
<!-- 
      <van-cell
        title="选择拆解时间"
        :value="date"
        @click="show = true"
        style="font-size: 0.4rem"
      />
      <van-calendar v-model="show" type="range" @confirm="onConfirm"  color="cadetblue" :min-date="minDate" :max-date="maxDate"/>  -->

      <van-cell title="选择单个日期" :value="date" @click="show = true" />
      <van-calendar v-model="show" @confirm="onConfirm" />
      <!-- <div> -->
    </div>
     
    <p style="font-size: 0.38rem; padding-left: 0.3rem">拆车件</p>
    <div
      style="
        background-color: white;
        margin-top: 0.5rem;
        width: 91%;
        margin: 0.5rem auto;
        padding: 0.2rem 0.2rem;
        border-radius: 23px;
      "
      v-for="(item, index) in list"
      :key="index"
      @click="ids=index"
    >
    
      <div class="ClassificationList">
        <span style="float: right;width: 39px;" @click="delect(index)" v-if="status != 2">x</span>
        <div>
          <span>拆车件分类</span>
          <select
            v-model="item.carAccessoriesId"
            placeholder="请选择"
            class="select"
          >
           <option value="">请选择</option>

            <option
              v-for="item in carAccessoriesIdList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
            <!-- <option value="2"vertical</option> -->
          </select>
        </div>
        <div>
          <span>名称</span>
          <input type="text" v-model="item.patrsName" />
        </div>
        <div>
          <span>数量</span>
          <input type="number" v-model="item.num" />
        </div>
        <div>
          <span>重量</span>
          <input type="number" v-model="item.weight" />
        </div>
        <div>
          <span>颜色</span>
          <input type="text" v-model="item.color" />
        </div>
        <div class="assembly">
          <span>五大总成</span>

          <van-radio-group class="group-radio" style="line-height: 0;" v-model="item.five_big" direction="horizontal">
            <van-radio class="radiosm" :name="1">包含</van-radio>
            <van-radio class="radiosm" :name="2">不包含</van-radio>
          </van-radio-group>
        </div>
       
        <div style="margin-top: 20px;">
            <span style="display:block;height: 0.5rem;">配件照片</span>
            <div v-if="!isWechat"> 
              <van-uploader v-model="item.img_box" multiple  style="width: 100%; height: 100%;" :max-count="4" :after-read="afterRead" accept="image/*" id="imgBox" :before-delete="beforeDelete_i"/>
            </div>
            <div v-else>
               <span class="imgbox" v-for="(item,index) in WXimg" :key="index">
                    <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                        <van-icon class="iconX" name="close" @click="delImgHan('arrPhoto',index)" />
                    </van-image>
                </span>
                <span v-if="WXimg.length <= 3" class="uploaderImg" @click="WeChooseImage(4,'ciagou')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
        </div>
      </div>
    </div>
    <div
      style="
        color: white;
        background-color: cadetblue;
        padding: 0.1rem;
        width: 84%;
        margin: 0 auto;
        height: 0.7rem;
        border-radius: 13px;
        font-size: 0.4rem;
        line-height: 0.7rem;
        text-align: center;
      "
      @click="addList()"
    >
      添加新项
    </div>
    <div
      class="table_two"
      style="
        background-color: white;
        padding: 0.2rem 0.2rem;
        margin-top: 0.5rem;
        margin: 0.5rem auto;
        width: 91%;
      "
    >
      <div class="first">
        <span> 大宗物拆解</span>
        <span> 重量（kg）</span>
      </div>

      <div
        class="first"
        style="margin-top: 0.2rem"
        v-for="item in list_a"
        :key="item.bulkDismantlingClassId"
      >
        <span>{{ item.name }}</span>
        <input type="number" v-model="item.weight" />
      </div>
      <!-- <div class="first">
          <span>废铜</span>
          <input type="text" v-model="we+ight_two"/>
        </div>
        <div class="first">
          <span>废铝</span>
          <input type="text" v-model="weight_three"/>
        </div>
        <div class="first">
          <span>合金</span>
          <input type="text" v-model="weight_four"/>
        </div>
        <div class="first">
          <span>废塑料</span>
          <input type="text" v-model="weight_five"/>
        </div>
        <div class="first">
          <span>废钢</span>
          <input type="text" v-model="weight_six"/>
        </div> -->
    </div>

    <div
      class="table_two"
      style="
        background-color: white;
        padding: 0.2rem 0.2rem;
        margin-top: 0.5rem;
        margin: 0.5rem auto;
        width: 91%;
      "
    >
      <div class="first">
        <span> 危废</span>
        <span> 重量（kg）</span>
      </div>

      <!-- <div class="first" style="margin-top: 0.2rem" v-for="(item,index) in dangerClass" :key="index">
          <span :value="item.id" ref="span">{{item.name}}</span>
          <input type="text" ref="spanVal"/>
        </div> -->
      <div class="first" v-for="item in list_b" :key="item.dangerClassId">
        <span>{{ item.name }}</span>
        <input type="number" v-model="item.weight" />
      </div>
      <!-- <div class="first">
          <span>动力蓄电池</span>
          <input type="text" v-model="weight_b"/>
        </div>
        <div class="first">
          <span>氟利昂</span>
          <input type="text" v-model="weight_c"/>
        </div>
        <div class="first">
          <span>三元催化器</span>
          <input type="text" v-model="weight_d"/>
        </div>
        <div class="first">
          <span>废防冻液</span>
          <input type="text" v-model="weight_e"/>
        </div>
        <div class="first">
          <span>废油</span>
          <input type="text" v-model="weight_f"/>
        </div> -->
    </div>
  </div>
</template>
<script>
import formdata from "../../utils/filter";
import {deleteCookie} from '../../utils/env'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
  data() {
    return {
      status:1,
      fileList: [],
      uploadImgBox: [],
      date: "",
      show: false,
      shows: false,
      shows_a: false,
      shows_b: false,
      disassembleTypeId: "请选择", //拆解类型
      database: "",
      carAccessoriesId: "", //拆解类型
      carAccessoriesIdList: "", //拆解类型
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2021, 12, 31),
      currentDate:new Date(),
      Tame:false,
      list: [
        // {
        //   carAccessoriesId: "", //分类
        //   patrsName: "", //名称
        //   num: "", //数量
        //   color: "", //颜色
        //   weight: "", //重量
        //   img_box: [], 
        //   img_boxs: [], 
        // },
      ], //
	ids:"",
      list_a: [],

      list_b: [],

      bulkDismantlingClass: "", //大宗物
      weight_one: "",
      weight_two: "",
      weight_three: "",
      weight_four: "",
      weight_five: "",
      weight_six: "",
      dangerClass: "", //废危
      weight_a: "",
      weight_b: "",
      weight_c: "",
      weight_d: "",
      weight_e: "",
      weight_f: "",
      startTime: "",
      endTimeL: "",
      WXimg:[],
      wximagelocalids:[],//判断上传张数
      toast:null,
    };
  },
   mounted () {
        this.wcConfig()
    },
    watch: {
      wximagelocalids:{
          handler(newValue, oldValue){
                if(newValue.length > 0 ){
              this.toast = Toast.loading({
                  duration: 0, // 持续展示 toast
                  forbidClick: true,
                  message: "上传中..."
              });
              this.wxUpload(newValue[0])
          }else{
              this.toast.clear();
          }
          },
          deep: true,
      }
  },
  async created() {
	  
    //大宗拆解物分类
    await this.$http.post("/index.php/index/Simply", { type: "bulkDismantlingClass" }).then((res) => {
        // //console.log(res.data.data);
       
        this.bulkDismantlingClass = res.data.data;

        let arr = []
        let obj = {}
        this.bulkDismantlingClass.forEach(element=>{
           obj = {bulkDismantlingClassId:element.id,name:element.name,weight:''}
           arr.push(obj)
         })
        //  //console.log(arr)
         this.list_a = arr
      });
    //废危解物分类
    await this.$http
      .post("/index.php/index/Simply", { type: "dangerClass" })
      .then((res) => {
        
        this.dangerClass = res.data.data;
        let arr = []
        let obj = {}
        this.dangerClass.forEach(element=>{
           obj = {dangerClassId:element.id,name:element.name,weight:''}
           arr.push(obj)
         })
         this.list_b = arr
      });
     
      await this.getData()
    
  },
  methods: {
	  	getData(){
			   // 获取保存的拆车件
			let data = encipherMent( JSON.stringify( {id: this.$route.query.ids,}))
                let param = new URLSearchParams();
                param.append("value",data);
    		this.$http.post("/index.php/index/Dismantled_Parts_Save/_get",param).then((res) => {
				  let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
				console.log(result)
				if(result.code == 0){
					if(result.data != ''){
					if (result.data.dismantledCarParts.length > 0) {
					
						let http = this.GLOBAL.BASE_URL;

						this.list = result.data.dismantledCarParts;
						
						this.list.forEach(element=>{
							if(element.img_boxs.length > 0){
							console.log(element.img_boxs)
							let arr = element.img_boxs
							let obj = {}
							let brr =[]
							arr.forEach(a=>{
								obj = {url : http + a}
								
								brr.push(obj)
								
							})
							
							element.img_box = brr
							
							}
							
					
						})
						}
						if (result.data.bulkWarehousing.length != 0) {
							this.list_a = result.data.bulkWarehousing;
							console.log(this.list_a)
						}
						if (result.data.dangerWarehousing.length != 0) {
							this.list_b = result.data.dangerWarehousing;
							console.log(this.list_b)
						}
						this.date = result.data.dismantleStartTime 
					}
				}else if(result.code == '-1'){
				this.$toast.fail(result.msg)
				this.$router.push('login')
				deleteCookie('token')
				}else{
					this.$toast.fail(result.msg)
				}
			});
			//拆解分类
			this.$http.post("/index.php/index/Simply", { type: "carAccessories" }).then((res) => {
				// //console.log(res);
				this.carAccessoriesIdList = res.data.data;
			});
		},
      zhuan(url){
                        let str = url
                        let str1 = str.replace('https','')
                        str1 = str1.replace('http','')
                        let str2 = 'https'+ str1
                        return str2
                    },
    confirmHan(e) {
      	  let date = this.$moment(e).format('YYYY-MM-DD h:mm:ss')
          this.date = date
          this.Tame = false
          //console.log(date)
    },
     beforeAvatarUpload(file) {
         //console.log(file)
          
               return new Promise((resolve, reject) => {
                       compress(file).then(res=>{
                        resolve(res)
                        //console.log(res)
                       })
                    }).then(res=>{
                        if(res == 0) {
                            this.$message({showClose: true,duration:1500,message: '图片大小不能大于15像素',type: 'error'});
                           return reject()
                        }else{
                          //console.log(res)
                            // return res
                        }
                    })
            },
    delect(index){

      //console.log(index)
      this.list.splice(index, 1)
      this.$forceUpdate()
      //console.log(this.list.length)
      if(this.list.length == 1){
        this.status = 2
      }
    },
    afterRead(file) {
      // this.$toast.fail('正在上传')
     this.$toast.loading({
      message: '上传中',
      forbidClick: true,
    });
      if (this.imgStatus == 1) {
        var Box = document.getElementsByClassName("van-uploader");
        var p = document.createElement("p"); //创建p节点
        p.innerHTML = "配件照片";
        Box[8].appendChild(p); //往div中添加p节点
        this.imgStatus = 2;
      }

      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 200);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          console.log(this.ids,this.list[this.ids])
              this.list[this.ids].img_boxs.push(res.data.data)
              //console.log(this.list)
              this.$forceUpdate()
              // this.$toast.fail('上传成功')
               this.$toast.success('上传成功');

               
      
        }
      });

      // //console.log(this.uploadImgBox)
    },
    beforeDelete_i(file,detail){
            var this_ = this
              //console.log(file)

              this_.list.forEach(a => {
                //console.log(a.img_box)
                a.img_box.forEach(function(b,index){
                  //console.log(index)
                  //console.log(detail.index)
                  if(b == file && index == detail.index){
                  a.img_box.splice(index, 1)

                  a.img_boxs.splice(index, 1)
                      this_.$forceUpdate()
                }
                })
                
                })

              //console.log(this.list)
              //console.log(typeof file)
         
        
      },

    //仅保存
    // SaveOnly(){

    // },
	//弹窗
    finish() {
	
      this.shows = true;
      this.shows_a = true;
    },

    goSave() {
      // this.shows = false;
      this.shows_a = false;
      this.shows_b = true;
    },

    //第二个取消
    finishEnd() {
      this.shows = false;
      this.shows_b = false;
    },

    dateFormat(time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      //  this.date = this.formatDate(date)
      // const [start, end] = date;
      // this.date = `${this.formatDate(start)}`;
      // //console.log(this.dateFormat(start));
      // //console.log(this.dateFormat(end));
      this.date = this.dateFormat(date);
      console.log( this.startTime)
      // this.endTime = this.dateFormat(end);
    },
    goDistribution(a) {
      this.shows = false;
     
        let arr = [];
        let obj = {};
        let arr1 = [];
        this.list.forEach((item) => {
          
           if(!item.patrsName &&!item.num&& !item.color&& !item.carAccessoriesId&& !item.weight && !item.five_big){
                  arr1 = []
            }else{
                 obj = {
                  patrsName: item.patrsName,
                  num: item.num,
                  color: item.color,
                  carAccessoriesId: item.carAccessoriesId,
                  weight: item.weight,
                  five_big: item.five_big,
                  // img_box:i.img_box,
                  img_boxs:item.img_boxs
                };
                arr1.push(obj);
            }
        });
        console.log(arr1)
        // arr.forEach((i) => {
         

        

                      
        //   this.database = arr1;
        //   //console.log(this.database);
        //   // return false;
        // });
		let aabb = {
            end_of_lifeVehiclesId: this.$route.query.id,
            id:this.$route.query.ids,
            type: a,
            bulkWarehousing: this.list_a,
            dangerWarehousing: this.list_b,
            dismantledCarParts: arr1,
            dismantleStartTime: this.date,
           
          }
		let data = encipherMent( JSON.stringify(aabb))
                let param = new URLSearchParams();
                param.append("value",data);
        this.$http.post("/index.php/index/Dismantled_Car_Parts/dismantleRecord",param ).then((res) => {
			    let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
            if (result.code == "0") {
              //console.log(a)
              if (a == '1') {
                this.$toast.success("保存成功");
              } else {
                // alert("1111")
                this.$toast.success(result.msg);
                this.$router.go(-1)
              }
            } else {
              this.$toast.fail(result.msg);
              this.shows_b = false
            }
          });
      
    },
    // getData(){
    //     this.$http.post('/index.php/index/Dismantled_Car_Parts/dismantleRecord')
    // }
    addList() {
      let cope = {
        
         
          carAccessoriesId: "", //分类
          patrsName: "", //名称
          num: "", //数量
          color: "", //颜色
          weight: "", //重量
          five_big: 2, //重量
          img_box: [], 
          img_boxs: [], 
        
      };
      this.list.push(cope);
      this.status = 1
      // this.$forceUpdate()


    },
    previousPage() {
      this.$router.go(-1);
    },
  },
 
};
</script>
<style lang="scss" scoped> 
html {
  height: 100%;
  background-color: #f5f5f5;
}
.DisassemblyRecord{
  .van-popup--center{
    width: 7rem !important;
    height: 3.3rem !important;
    // background-color: red;
  }
.Dialog {
  width: 100%;
  height: calc(100% - 20px);
  border: 1px solid #000;
  .title {
    height: 0.85rem;
    text-align: center;
    font-size: 0.38rem;
    line-height: 0.85rem;
    background-color: cadetblue;
    color: white;
  }
  .mains {
    height: 1rem;
    text-align: center;
    font-size: 0.38rem;
    line-height: 1rem;
    margin: 0;
    // border-top: 1px solid;
    // border-bottom: 1px solid;
  }
  .btnList {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid;
    height: 0.95rem;
    span {
      width: 50%;
      display: inline-block;
      text-align: center;
      font-size: 0.38rem;
      line-height: 0.95rem;
      // border: 0.05px solid ;
    }
  }
}

}

.van-cell__value {
  border: 1px solid;
  text-align:center
}
.shadown {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  position: absolute;
  top: 0;
  z-index: 9999;
}
.ClassificationList {
  span {
    font-size: 0.38rem;
    width: 2rem;
    display: inline-block;
    height: 0.5rem;
    text-align: center;
  }
  .select {
    width: 59%;
    margin-left: 0.5rem;
    height: 0.6rem;
    border-radius: 0px;
  }
  input {
    width: 58%;
    margin-left: 0.5rem;
    
    border-radius: 0px;
    border: 1px solid;
    font-size: 0.38rem;
  }
  div{
	  margin: 6px 0;
  }
  div:nth(3) {
    input {
      width: 30%;
    }
  }
}
.header {
  padding: 0 20px;
  background-color: cadetblue;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-size: 0.4rem;
  display: flex;
   position: fixed;
   top: 0;
   width: calc(100% - 40px);
   z-index: 9;
  justify-content: space-between;
  span {
    display: inline-block;
  }
  span:nth-child(1) {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/u39.png);
    background-size: 100%;
    margin-top: 8px;
  }
}
.first {
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
  span {
    display: inline-block;
    font-size: 0.4rem;
    width: 30%;
    text-align: center;
  }
  input {
    display: inline-block;
    width: 25%;
    height: 0.4rem;
    border-radius: 0px;
    font-size: 0.4rem;
    -webkit-appearance: none; /*去除系统默认的样式*/
  }
}
.firsts {
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
  span {
    display: inline-block;
    font-size: 0.4rem;
    width: 20%;
    text-align: center;
  }

  input {
    display: inline-block;
    width: 20%;
    height: 0.4rem;
    border-radius: 0px;
    font-size: 0.4rem;
    -webkit-appearance: none; /*去除系统默认的样式*/
  }
}
.table_two {
}
.van-popup {
	padding: 20px;
	
}
.box{
  margin-top: 1.5rem;
}
.van-popup--bottom{
  width: calc(100% - 40px);
}
.assembly{
  display: flex;
  height: 30px;
  margin-top: 10px;
  .group-radio{
    margin-left: 20px;
  }
}
   .uploaderImg{
        display: inline-block !important;
        width: 80px !important;
        height: 80px !important;
        border: 1px solid #eee !important;
        position: relative !important;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}

</style>